import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ContactButton from '../components/ContactButton.js'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Viewsource Limited`, `User Interfaces`, `UI`]} />

    <div className="mx-auto leading-normal">
      <h2 className="bg-clouds inline-block mb-8 p-3">We create delightful User Interfaces</h2>
      <p className="leading-normal pb-3">
        Viewsource is a software development consultancy that brings your digital product ideas to life.
        We make Web and Mobile Applications that are effective, easy to understand, and a joy to use.
      </p>
      <p className="leading-normal pb-3">
        We approach each project with a long-term view, ensuring sustainability, maintainability, and
        stability. We build a rock-solid base that will serve you well for the years ahead.
      </p>
      <p className="leading-normal">
        Our objective is to help you create quality software that scales, performs well, improves user
        satisfaction, and helps you achieve your business goals.
      </p>

      <Link
        to="/contact"
        className="no-underline text-viewsource border-b-2 border-viewsource border-solid hidden xs:inline"
      >
        <br />
        Contact Us
      </Link>
      <div className="block xs:hidden pt-8">
        <ContactButton />
      </div>
    </div>
  </Layout>
)

export default IndexPage
